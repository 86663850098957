@font-face {
    font-family: "JalnanGothic";
    font-display: swap;

    src: url("/src/styles/fonts/JalnanGothicTTF.ttf") format("truetype");

    font-weight: 700;
}

@font-face {
    font-family: "Pretendard";
    font-display: swap;

    src: url("/src/styles/fonts/Pretendard-Regular.woff") format("woff");

    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    font-display: swap;

    src: url("/src/styles/fonts/Pretendard-Medium.woff") format("woff");

    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    font-display: swap;

    src: url("/src/styles/fonts/Pretendard-Bold.woff") format("woff");

    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Pretendard";
    font-display: swap;

    src: url("/src/styles/fonts/Pretendard-ExtraBold.woff") format("woff");

    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "NanumBrush";
    font-display: swap;
    src: url("/src/styles/fonts/NanumBrush.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'S-CoreDream1';
    src: url('/src/styles/fonts/S-CoreDream-1Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'S-CoreDream2';
    src: url('/src/styles/fonts/S-CoreDream-2ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'S-CoreDream3';
    src: url('/src/styles/fonts/S-CoreDream-3Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'S-CoreDream4';
    src: url('/src/styles/fonts/S-CoreDream-4Regular.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'S-CoreDream5';
    src: url('/src/styles/fonts/S-CoreDream-5Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'S-CoreDream6';
    src: url('/src/styles/fonts/S-CoreDream-6Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'S-CoreDream7';
    src: url('/src/styles/fonts/S-CoreDream-7ExtraBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'S-CoreDream8';
    src: url('/src/styles/fonts/S-CoreDream-8Heavy.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'S-CoreDream9';
    src: url('/src/styles/fonts/S-CoreDream-9Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}